// PatientList.jsx
import React, { useEffect, useState } from 'react';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarColumnsButton, GridToolbarQuickFilter , DataGrid} from '@mui/x-data-grid';
import axios from '../common/axios';
import { CircularProgress, Box, Modal, IconButton, Tooltip, Button, Toolbar, Typography, Snackbar, Alert } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewListIcon from '@mui/icons-material/ViewList';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { koKR } from '@mui/x-data-grid/locales';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from 'dayjs';

const PatientList = () => {
    const [patients, setPatients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [details, setDetails] = useState([]);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

    const handleDownload = async (filePath) => {
      try {
        const response = await axios.get(`/downloadfile?filePath=${encodeURIComponent(filePath)}`, {
          responseType: 'blob', // 바이너리 데이터를 받기 위해 필요
        });
    
        // 응답에서 파일명 추출 (백엔드에서 Content-Disposition 헤더를 설정한 경우)
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'downloaded_file';
    
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?(.+)"?/);
          if (match && match[1]) {
            filename = decodeURIComponent(match[1]);
          }
        } else {
          // 파일 경로에서 파일명 추출
          filename = filePath.split('/').pop() || 'downloaded_file';
        }
    
        // Blob 객체 생성
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
    
        // 다운로드 링크 생성 및 클릭
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    
        // 클린업
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('파일 다운로드 에러:', error);
        setSnackbar({ open: true, message: '파일 다운로드 중 오류가 발생했습니다.', severity: 'error' });
      }
    };

    useEffect(() => {
        if (rowSelectionModel.length > 0) {
          const selected = details.filter((detail) => rowSelectionModel.includes(detail.id));
          setSelectedDetails(selected);
        } else {
          setSelectedDetails([]);
        }
      }, [rowSelectionModel, details]);

    useEffect(() => {
        // 환자 목록 불러오기
        axios.get('/getPatientInfo') // 상대 경로로 수정
            .then(response => {
                if (response.data.success) {
                    setPatients(
                        response.data.data.map((v)=>({...v, grant_decided:v.grant_decided === 0?"계산 필요":"계산 완료"}))
                        );
                } else {
                    console.error('환자 목록을 가져오는 데 실패했습니다:', response.data.message);
                    setSnackbar({ open: true, message: '환자 목록을 가져오는 데 실패했습니다.', severity: 'error' });
                }
            })
            .catch(error => {
                console.error('환자 목록 가져오기 에러:', error);
                setSnackbar({ open: true, message: '환자 목록 가져오기 중 에러가 발생했습니다.', severity: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);


    const handleViewDetails = (patient) => {
        setSelectedPatient(patient);
        setDetailsLoading(true);
        setModalOpen(true);
        setSelectedDetails([]); // 기존 선택 초기화

        // 환자 세부 정보 불러오기
        axios.get('/getPatientDetails', {
            params: {
                number: patient.number,
                period: patient.period
            }
        })
            .then(response => {
                if (response.data.success) {
                    const processedDetails = _.sortBy(
                        response.data.data
                          // .filter(v => !v.item.includes('항목')&&!v.item.includes('환자등록') &&  !v.item.includes('본인')&&  !v.item.includes('코드') &&  !v.item.includes('일자') &&   !/^\d+$/.test(v.item) )
                          .filter(v => {
                            // "", null, undefined를 빈 값으로 처리하기 위한 함수
                            const isEmpty = value => !value && value !== 0;
                            
                            // 검사할 필드들의 빈 값 개수를 계산
                            const emptyCount = [v.item, v.date, v.code, v.detailName]
                              .filter(isEmpty)
                              .length;
                            
                            // 빈 값이 2개 미만인 것만 통과 (즉, 2개 이상 빈 값이면 필터링됨)
                            return emptyCount < 2;
                          })
                          .map((v, index) => ({
                            ...v,
                            id: v.id,
                            amount: Number(v.amount),
                            total: Number(v.total),
                            personal_contribution: Number(v.personal_contribution),
                            public_contribution: Number(v.public_contribution),
                            full_personal_contribution: Number(v.full_personal_contribution),
                            non_payable: Number(v.non_payable),
                            checked: v.checked || false // 체크 상태 추가 (백엔드에서 제공 시 필요)

                          })),
                        'code'
                      );
                      setDetails(processedDetails);
                      setRowSelectionModel(processedDetails.filter(v=>v.checked).map(v=>v.id))

                } else {
                    console.error('환자 세부 정보를 가져오는 데 실패했습니다:', response.data.message);
                    setSnackbar({ open: true, message: '환자 세부 정보를 가져오는 데 실패했습니다.', severity: 'error' });
                }
            })
            .catch(error => {
                console.error('환자 세부 정보 가져오기 에러:', error);
                setSnackbar({ open: true, message: '환자 세부 정보 가져오기 중 에러가 발생했습니다.', severity: 'error' });
            })
            .finally(() => {
                setDetailsLoading(false);
            });
    };

    const handleClose = () => {
        setModalOpen(false);
        setDetails([]);
        setSelectedPatient(null);
        setSelectedDetails([]);

    };

    const handleGrantConfirm = () => {
        if (selectedDetails.length === 0) {
          setSnackbar({ open: true, message: '세부 항목을 선택해주세요.', severity: 'warning' });
          return;
        }
    
        // 선택된 항목의 금액 합산
        const totalGrantAmount = selectedDetails.reduce((acc, detail) => acc + (detail.amount || 0), 0);
    
        // SweetAlert2를 이용한 확인 다이얼로그
        Swal.fire({
          title: '지원 금액 확정',
          text: `선택된 항목의 소계는 ${totalGrantAmount.toLocaleString()}원입니다. 정말로 확정하시겠습니까?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          zIndex:1400
        }).then((result) => {
          if (result.isConfirmed) {
            // 백엔드로 지원 금액 및 체크 여부 업데이트 요청
            axios.post('/confirmGrantAmount', { // 상대 경로로 수정
              number: selectedPatient.number,
              period: selectedPatient.period,
              grantAmount: totalGrantAmount,
              selectedDetails: selectedDetails.map(detail => ({
                id: detail.id,
                checked: true // 선택된 항목을 체크된 상태로 업데이트
              }))
            })
              .then(response => {
                if (response.data.success) {
                  setSnackbar({ open: true, message: response.data.message, severity: 'success' });
                  // 환자 목록을 갱신하여 업데이트된 grant_amount 반영
                  setModalOpen(false);
                  return axios.get('/getPatientInfo');
           
                } else {
                  setSnackbar({ open: true, message: '지원 금액 업데이트에 실패했습니다: ' + response.data.message, severity: 'error' });
                }
              })
              .then(response => {
                if (response && response.data.success) {
                  setPatients(response.data.data);
                }
              })
              .catch(error => {
                console.error('지원 금액 업데이트 에러:', error);
                setSnackbar({ open: true, message: '지원 금액 업데이트 중 에러가 발생했습니다.', severity: 'error' });
              });
          } 
        });
      }

      const patientColumns = [
        { field: 'number', headerName: '환자등록번호', headerAlign: 'center', align: 'center', flex: 1 },
        { field: 'patientName', headerName: '환자성명', headerAlign: 'center', align: 'center', flex: 1 },
        { field: 'period', headerName: '진료기간', headerAlign: 'center', align: 'center', flex: 1 },
        { field: 'room', headerName: '병실', headerAlign: 'center', align: 'center', flex: 1 },
        { field: 'category', headerName: '환자구분', headerAlign: 'center', align: 'center', flex: 1 },
        { field: 'remarks', headerName: '비고', headerAlign: 'center', align: 'center', flex: 1 },
        { 
            field: 'grant_amount', headerName: '지원 금액', headerAlign: 'center', align: 'center', type: 'number', flex: 1,
            valueGetter: (value, row) => {
                return value ? Number(value).toLocaleString() + "원" : '';
            }
        },
        { field: 'grant_decided', headerName: '처리 상태', headerAlign: 'center', align: 'center', flex: 1 },
        {
            field: 'calculation_date',
            headerName: '지원금 결정 일자', headerAlign: 'center', align: 'center', flex: 1.5,
            valueGetter: (value, row) => {
                return value ? dayjs(value).format("YYYY년 MM월 DD일 HH:mm:ss") : '미확정';
            },
        },
        {
            field: 'viewDetails',
            headerName: '진료비 상세 보기', headerAlign: 'center', align: 'center', flex: 1, 
            sortable: false, filterable: false,
            renderCell: (params) => (
                <Tooltip title="진료비 상세 보기">
                    <IconButton
                        color="gray"
                        onClick={() => handleViewDetails(params.row)}
                    >
                        <ViewListIcon />
                    </IconButton>
                </Tooltip>
            )
        },
        {
          field: 'file_url',
          headerName: '파일',
          headerAlign: 'center',
          align: 'center',
          flex: 2.5,
          sortable: false,
          filterable: false,
          renderCell: (params) => {
            if (!params.value) {
              return '없음';
            }
            const fileUrls = JSON.parse(params.value); // file_url이 JSON 문자열로 저장된 경우
            return (
              <div>
                {fileUrls.map((url, index) => (
                  <span key={index}>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDownload(url.trim());
                      }}
                    >
                      파일 {index + 1}
                    </a>
                    {index < fileUrls.length - 1 && ' | '}
                  </span>
                ))}
              </div>
            );
          },
        },
    ];

    const detailColumns = [
        // { field: 'id', headerName: 'id', width: 150 },
        { field: 'item', headerName: '항목', width: 100 },
        { field: 'date', headerName: '일자', width: 130 },
        { field: 'code', headerName: '코드', width: 100 },
        { field: 'detailName', headerName: '명칭', width: 200 },
        { field: 'amount', headerName: '금액', width: 100 , type:'number'},
        { field: 'count', headerName: '횟수', width: 60 },
        { field: 'days', headerName: '일수', width: 60 },
        { field: 'total', headerName: '총액', width: 100 , type:'number'},
        { field: 'personal_contribution', headerName: '본인부담금', width: 100 , type:'number'},
        { field: 'public_contribution', headerName: '공단부담금', width: 100 , type:'number'},
        { field: 'full_personal_contribution', headerName: '전액본인부담', width: 100 , type:'number'},
        { field: 'non_payable', headerName: '비급여', width: 100 , type:'number'},
        // 체크박스는 checkboxSelection으로 대체
    ];

    const columnGroupingModel = [
        {
          groupId: 'info',
          headerName:'급여',
          children: [
            {
              groupId: '1',
              headerName:"일부본인부담",
              children: [{ field: 'personal_contribution' }, { field: 'public_contribution' }],
            },
            {field:'full_personal_contribution'}      
              
          ],
        },
      ];
      

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        height:"90vh"
    };

    const CustomGridToolbar = () => {
        const handleDownload = async () => {
          try {
            const response = await axios.get('/downloadfile?filePath=imageScanner.apk', {
              responseType: 'blob', // 중요: 바이너리 데이터를 받기 위해 필요
            });
            
            // Blob 객체 생성
            const blob = new Blob([response.data], { 
              type: 'application/vnd.android.package-archive'
            });
            
            // 다운로드 링크 생성
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'app.apk'); // APK 파일명
            document.body.appendChild(link);
            link.click();
            
            // cleanup
            window.URL.revokeObjectURL(url);
            link.remove();
          } catch (error) {
            console.error('APK 다운로드 에러:', error);
          }
        };
      
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <Button
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
            >
              APK 다운로드
            </Button>
            <div style={{ flexGrow: 1 }} />
            <GridToolbarQuickFilter />
          </GridToolbarContainer>
        );
      };
      

    return (
        <Box display="flex" flexDirection="column" height="90vh" width="98%" sx={{p:2}}>
            {/* 환자 목록 그리드 */}
            {/* <Box flexGrow={1} sx={{ p: 4 }}> */}
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                    <Typography variant='h6'>진료비 신청 내역</Typography>
                    <DataGrid
                    
                     localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                        density='compact'
                        rows={patients.map((patient, index) => ({ id: index + 1, ...patient }))}
                        columns={patientColumns}
                        pageSize={25}
                        rowsPerPageOptions={[25, 50, 100]}
                        disableSelectionOnClick
                        showColumnVerticalBorder
                        showCellVerticalBorder
                        sx={{ width: '100%' }}
                        disableDensitySelector
                        slots={{ toolbar: CustomGridToolbar }}
                        // slotProps={{
                        //   toolbar: {
                        //     showQuickFilter: true,
                        //   },
                        // }}
                    />
                    </>
                )}
            {/* </Box> */}

            {/* 세부 정보 모달 */}
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="patient-details-title"
                aria-describedby="patient-details-description"
                

            >
                <Box sx={style}

                >
                    <Typography variant="h6" id="patient-details-title" gutterBottom>
                    {selectedPatient?.patientName}님 진료비 세부내역
                    </Typography>
                    <Toolbar>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGrantConfirm}
                            disabled={selectedDetails.length === 0}
                        >
                            지원 금액 확정
                        </Button>
                        <Typography variant="body2" style={{ marginLeft: 'auto' }}>
                            선택된 항목의 소계: {_.sumBy(selectedDetails, "total")}
                        </Typography>
                    </Toolbar>
                    {detailsLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <DataGrid
                        
                     localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                            rows={details}
                            columns={detailColumns}
                            density='compact'
                            pageSize={10}
                            rowsPerPageOptions={[10, 25, 50]}
                            checkboxSelection
                            disableSelectionOnClick
                            rowSelectionModel={rowSelectionModel}
                            columnGroupingModel={columnGroupingModel}
                            showColumnVerticalBorder
                            showCellVerticalBorder
                            onRowSelectionModelChange={(newSelection) => {
                              setRowSelectionModel(newSelection);
                            }}
                            sx={{ width: '100%' , height:'calc(100% - 100px)'}}
                        />
                    )}
                </Box>
            </Modal>

            {/* Snackbar for Notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PatientList;